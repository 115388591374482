import React from 'react';
import { Navbar, NavbarGroup, Alignment, NavbarDivider, Button, Classes } from '@blueprintjs/core';
import { useLocation } from '@reach/router';

import logo from '@assets/logo-narkomed.png';

import * as styles from './index.module.scss';

import { CARD_TYPE } from '@resources/cards/_card';
import { isQueryKeyEqual, removeTrailingSlash } from '@resources/helpers/router';
import { User } from '@resources/users/_user';
import { Office } from '@resources/offices/_office';

type Props = {
    profile: User;
    office: Office;
    isAdmin: boolean;
    onNavigate: (uri: string) => void;
    onLogout: () => void;
};

export const Header = (props: Props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const getButtonStyle = (uri: string, query?: { [key: string]: string }): string => {
        const curPath = removeTrailingSlash(location.pathname);
        const menuUri = removeTrailingSlash(uri);
        const queryKeyEqual = isQueryKeyEqual(query);

        return curPath === menuUri && queryKeyEqual ? Classes.BUTTON : Classes.MINIMAL;
    };

    return (
        <div className={styles.header}>
            <div className={styles.logo}>
                <a href="/" className={styles.href}>
                    <img src={logo} className={styles.logo_image} />
                </a>
            </div>
            <Navbar className={styles.navbar}>
                <NavbarGroup align={Alignment.LEFT}>
                    {props.profile.username ? (
                        <React.Fragment>
                            <Button
                                className={getButtonStyle('/')}
                                icon="add"
                                small={true}
                                text="Создать карточку"
                                onClick={() => props.onNavigate('/')}
                            />
                            <Button
                                className={getButtonStyle('/cards', { type: CARD_TYPE.RESEARCH })}
                                icon="properties"
                                small={true}
                                text="Медобследованные"
                                onClick={() =>
                                    props.onNavigate(`/cards?type=${CARD_TYPE.RESEARCH}`)
                                }
                            />
                            <Button
                                className={getButtonStyle('/cards', { type: CARD_TYPE.PATIENT })}
                                icon="list"
                                small={true}
                                text="Пациенты"
                                onClick={() => props.onNavigate(`/cards?type=${CARD_TYPE.PATIENT}`)}
                            />
                            <Button
                                className={getButtonStyle('/orgs')}
                                icon="build"
                                small={true}
                                text="Организации"
                                onClick={() => props.onNavigate('/orgs')}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment />
                    )}

                    {props.isAdmin && (
                        <Button
                            className={getButtonStyle('/reports')}
                            icon="timeline-line-chart"
                            text="Отчеты"
                            small={true}
                            onClick={() => props.onNavigate('/reports')}
                        />
                    )}
                    {!!props.profile.username ? <NavbarDivider /> : <React.Fragment />}

                    {!!props.profile.username ? (
                        <Button
                            className={getButtonStyle('/account')}
                            icon={'user'}
                            small={true}
                            text={
                                !!props.office.name
                                    ? `${props.profile.username} (${props.office.name})`
                                    : props.profile.username
                            }
                            onClick={() => {
                                props.onNavigate('/account');
                            }}
                        />
                    ) : (
                        <Button
                            className={getButtonStyle('/auth/login')}
                            icon={'log-in'}
                            small={true}
                            text={'Войти '}
                            onClick={() => {
                                props.onNavigate('/auth/login');
                            }}
                        />
                    )}
                    {!!props.profile.username && (
                        <Button
                            className={Classes.MINIMAL}
                            icon="log-out"
                            small={true}
                            text="Выйти"
                            onClick={props.onLogout}
                        />
                    )}
                </NavbarGroup>
            </Navbar>
        </div>
    );
};
