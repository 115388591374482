import React from 'react';
import { Spinner, Intent } from '@blueprintjs/core';

import * as styles from './index.module.scss';

type Props = {};

export const Loading = (props: Props) => (
    <div className={styles.container}>
        <Spinner intent={Intent.SUCCESS} size={25} />
        <div className={styles.label}>Загрузка...</div>
    </div>
);

type LoadingOverlayProps = {
    isLoading: boolean;
    children: React.ReactChild | React.ReactChild[];
};

export const LoadingOverlay = (props: LoadingOverlayProps) => (
    <div className={styles.overlay}>
        {props.isLoading && <Loading />}
        {props.children}
    </div>
);
