import React from 'react';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import { connect, useDispatch } from 'react-redux';

import { Loading } from '@components/ui/loading';
import { Header } from '@components/header';
import { LayoutCom } from '@components/layout-com';

import { CONFIG_SITE_NAME } from '@resources/helpers/config';
import { authLogout } from '@resources/users/actions';
import { selectIsAdmin, selectIsAuthorized, selectProfile } from '@resources/users/selectors';
import { GlobalState } from '@resources/reducers';
import { User } from '@resources/users/_user';
import { selectIsInitialLoading } from '@resources/actions/selectors';
import { Footer } from '@components/footer';
import { selectOfficeById } from '@resources/offices/selectors';
import { Office } from '@resources/offices/_office';

type PropsCom = {
    children: React.ReactChild | React.ReactChild[];
    title?: string;
    isLoading?: boolean;
};

type Props = {
    profile: User;
    isAdmin: boolean;
    isAuthorized: boolean;
    isInitialLoading: boolean;
    office: Office;
};

export const Layout = connect(mapStateToProps)((props: Props & PropsCom) => {
    const dispatch = useDispatch();

    const title = props.title ? props.title + ' | ' + CONFIG_SITE_NAME : CONFIG_SITE_NAME;

    React.useEffect(() => {
        if (!props.isAuthorized) {
            navigate('/auth/login');
        }
    }, [props.isAuthorized]);

    return (
        <div>
            <Helmet title={title}>
                <link rel="icon" type="image/png" href="/favicon.png"></link>
            </Helmet>
            <LayoutCom>
                <Header
                    office={props.office}
                    profile={props.profile}
                    onNavigate={(uri) => {
                        navigate(uri);
                    }}
                    onLogout={() => {
                        dispatch(authLogout());
                    }}
                    isAdmin={props.isAdmin}
                />
                <div>{props.children}</div>
                <Footer />
            </LayoutCom>
            {(props.isLoading || props.isInitialLoading) && <Loading />}
        </div>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        profile: selectProfile(state),
        isAdmin: selectIsAdmin(state),
        isAuthorized: selectIsAuthorized(state),
        isInitialLoading: selectIsInitialLoading(state),
        office: selectOfficeById(state, state.users.profile.office_id),
    };
}
