import { GlobalState } from '@resources/reducers';
import {
    CardsActions,
    UsersActions,
    ReportsActions,
    PricesActions,
    ConfigActions,
    OrgsActions,
} from '@resources/actions/_state';

export const selectCardsActions = (state: GlobalState): CardsActions => state.actions.cards;
export const selectUsersActions = (state: GlobalState): UsersActions => state.actions.users;
export const selectReportsActions = (state: GlobalState): ReportsActions => state.actions.reports;
export const selectPricesActions = (state: GlobalState): PricesActions => state.actions.prices;
export const selectIsInitialLoading = (state: GlobalState): boolean => {
    const { users, prices } = state.actions;
    return users.users.loading || users.user_proflie.loading || prices.prices.loading;
};
export const selectConfigActions = (state: GlobalState): ConfigActions => state.actions.config;
export const selectOrgsActions = (state: GlobalState): OrgsActions => state.actions.orgs;
