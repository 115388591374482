import React from 'react';

import { IS_BROWSER } from '@resources/helpers/ssr';

export function useQueryParam(name: string): string {
    if (IS_BROWSER) {
        const params = new URLSearchParams(window.location.search);
        const [p, setP] = React.useState<string>(params.get(name));

        React.useEffect(() => {
            setP(params.get(name));
        }, [params.get(name)]);

        return p;
    }

    return '';
}

// Проверяем совпадение
export function isQueryKeyEqual(query: { [key: string]: string } = {}): boolean {
    if (IS_BROWSER) {
        const params = new URLSearchParams(window.location.search);

        for (let key in query) {
            if (params.get(key) !== query[key]) {
                return false;
            }
        }

        return true;
    }

    return false;
}

export function removeTrailingSlash(site): string {
    return site.replace(/\/$/, '');
}
