import React from 'react';

import * as styles from './index.module.scss';

type Props = {};

export const Footer = (props: Props) => {
    const year = new Date().getFullYear();
    return <div className={styles.container}>Powered by Gogo Technology @ {year}</div>;
};
